import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Thunk för att hämta en specifik produkt
export const fetchProductById = createAsyncThunk(
  "epdProducts/fetchProductById",
  async (productId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/epd/products/${productId}`
    );
    return response.data;
  }
);

// Thunk för att hämta alla produkter
export const fetchProducts = createAsyncThunk(
  "epdProducts/fetchProducts",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/epd/products`
    );
    return response.data;
  }
);

// Thunk för att uppdatera en produkt
export const updateProduct = createAsyncThunk(
  "epdProducts/updateProduct",
  async ({ productId, data }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/epd/products/${productId}`,
      data
    );
    return response.data;
  }
);

// Thunk för att kontrollera om en URL är giltig
export const checkUrl = createAsyncThunk(
  "epdProducts/checkUrl",
  async (url) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/epd/proxy?url=${encodeURIComponent(url)}`);
    const status = response.status;
    return { status };  // Returnera ett objekt istället
  }
);

const epdProductSlice = createSlice({
  name: "epdProducts",
  initialState: {
    products: [],
    loading: false,
    error: null,
    updateStatus: "idle",
    urlIsValid: null,
  },
  reducers: {
    resetUrlIsValid: (state) => {
      state.urlIsValid = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Hantering av fetchProducts
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Hantering av fetchProductById
      .addCase(fetchProductById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Hantering av updateProduct
      .addCase(updateProduct.pending, (state) => {
        state.updateStatus = "loading";
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        // Hitta indexet för den uppdaterade produkten i listan
        const index = state.products.findIndex(
          (product) =>
            product.SustEpdChecksum === action.payload.SustEpdChecksum
        );
        // Kontrollera om den uppdaterade produkten faktiskt har ändrats
        if (
          index !== -1 &&
          JSON.stringify(state.products[index]) !==
            JSON.stringify(action.payload)
        ) {
          // Uppdatera endast den specifika produkten i listan
          state.products[index] = action.payload;
        }
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.error = action.error.message;
      })
      // Hantering av checkUrl
      .addCase(checkUrl.fulfilled, (state, action) => {
        state.urlIsValid = action.payload.status === 200;
      })
      .addCase(checkUrl.rejected, (state) => {
        state.urlIsValid = false;
      });
  },
});

export const { resetUrlIsValid } = epdProductSlice.actions;
export default epdProductSlice.reducer;
