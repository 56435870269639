import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import ProductCard from "./ProductCard";
import "./ProductSearch.css";
import { fetchProducts } from "../features/epdProducts/epdProductSlice";
import { setShowModal } from "../features/epdProducts/epdReducer";
import { fetchEpdStatusCounts } from "../features/epdProducts/epdStatusCountsSlice";
import ProductModal from "./ProductModal/ProductModal";
import { statusLabelMap } from '../config/statusConfig';

function ProductSearch() {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.epdProducts.products);
  const [searchTerm, setSearchTerm] = useState("");
  const showModal = useSelector((state) => state.epd.showModal);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [statusFilters, setStatusFilters] = useState({
    all: true,
    new: false,
    edit: false,
    validation: false,
    export: false,
    exported: false,
    done: false,
  });

  const [filteredProducts, setFilteredProducts] = useState(products);

  const closeModal = () => {
    // console.log("Closing modal");

    dispatch(setShowModal(false));
    dispatch(fetchProducts());
    dispatch(fetchEpdStatusCounts());
  };

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  // Debounce searchTerm
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 250);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  // Filter products by debouncedSearchTerm and statusFilters
  useEffect(() => {
    const filterProducts = () => {
      return products.filter((product) => {
        const productName =
          product.tabsData[0].SustEpdProductName.toLowerCase();
        const systemId = product.SustEpdChecksum.toString().toLowerCase();
        const sustEpdId = product.SustEpdId.toString().toLowerCase(); // Antagen egenskap för SustEpdId

        // Använd ExtractaStatus istället för Status
        let productStatus = product.ExtractaStatus
          ? product.ExtractaStatus.toLowerCase()
          : "new";

        return (
          (productName.includes(debouncedSearchTerm.toLowerCase()) ||
            systemId.includes(debouncedSearchTerm.toLowerCase()) ||
            sustEpdId.includes(debouncedSearchTerm.toLowerCase())) && // Lägg till kontroll för SustEpdId
          (statusFilters.all || statusFilters[productStatus])
        );
      });
    };

    setFilteredProducts(filterProducts());
  }, [products, debouncedSearchTerm, statusFilters]);

  // Toggle status filter
  const toggleStatusFilter = (statusKey) => {
    setStatusFilters((prevFilters) => {
      const status = statusKey.toLowerCase();

      if (status === "all") {
        return {
          all: true,
          new: false,
          edit: false,
          validation: false,
          export: false,
          exported: false,
          done: false,
        };
      }

      const newFilters = {
        ...prevFilters,
        [status]: !prevFilters[status],
        all: false,
      };

      const activeFilters = Object.values(newFilters).filter((value) => value);
      if (activeFilters.length === 0) {
        newFilters.all = true;
      }
      return newFilters;
    });
  };


  return (
    <div className="main-container">
      <div className="filter-container">
        <h5>Status</h5>
        <Form>
          {Object.keys(statusFilters).map((key) => {
            const label = statusLabelMap[key] || "Okänd"; // Hanterar fallback för okända statusar

            return (
              <Form.Check
                type="checkbox"
                label={label}
                checked={statusFilters[key]}
                onChange={() => toggleStatusFilter(key)}
                key={key}
                id={`checkbox-${key}`}
              />
            );
          })}
        </Form>
      </div>
      <Container className="product-list-container">
        <Row>
          <Col md={12}>
            <Form.Group className="SearchBar">
              <Form.Control
                type="text"
                placeholder="Sök på namn, epdid eller systemid..."
                size="lg"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {filteredProducts.length === 0 ? (
            <Col>
              <p>Inga produkter hittades.</p>
            </Col>
          ) : (
            filteredProducts.map((product, index) => (
              <Col xs={3} md={3} key={product.SustEpdChecksum || index}>
                <ProductCard product={product} />
              </Col>
            ))
          )}
        </Row>
      </Container>
      {showModal && <ProductModal closeModal={closeModal} />}
    </div>
  );
}

export default ProductSearch;
