import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Image, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateProduct } from "../../features/epdProducts/epdProductSlice";
import "./ProductModal.css";
import ProductModalFooter from "./ProductModalFooter";
import ProductModalHeader from "./ProductModalHeader";
import ProductForm from "./ProductForm";
import { useAuth0 } from "@auth0/auth0-react"; 


function ProductModal({ closeModal }) {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const showModal = useSelector((state) => state.epd.showModal);
  const activeProduct = useSelector((state) => state.epd.activeProduct);
  // const updateStatus = useSelector((state) => state.epdProducts.updateStatus);
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState(activeProduct || {});
  const [changedFields, setChangedFields] = useState({
    common: {}, // Håll ändrade status för fält som delas mellan tabs
    tabs: { 0: {} }, // Initialisera med en tom struktur för tab 0
  });
  const [activeTab, setActiveTab] = useState("0");
  const [tabKeys, setTabKeys] = useState(["0"]);
  const [tabsData, setTabsData] = useState(
    activeProduct?.tabsData || { 0: {} }
  );
  const [isChanged, setIsChanged] = useState(false);
  const handleAlertClose = () => setShowAlert(false);
  const [shouldCalculate, setShouldCalculate] = useState(false);

  useEffect(() => {
    if (showModal && activeProduct) {
      console.log(
        "Modal opened for product with SustEpdChecksum:",
        activeProduct.SustEpdChecksum
      );
      setFormData(activeProduct);
      setChangedFields({
        common: {}, // Återställ gemensamma ändrade fält
        tabs: tabKeys.reduce((acc, key) => {
          acc[key] = {}; // Återställ ändrade fält för varje tab, även om de är tomma
          return acc;
        }, {}),
      });
      setTabKeys(Object.keys(activeProduct.tabsData || { 0: {} }));
      setTabsData(activeProduct.tabsData || { 0: {} });
      setIsChanged(false);
    }
  }, [showModal, activeProduct]);


  const addTab = () => {
    const newTabIndex = `${tabKeys.length}`;
    setTabsData({ ...tabsData, [newTabIndex]: {} });
    setTabKeys([...tabKeys, newTabIndex]);
    setActiveTab(newTabIndex);
  };

  const removeTab = () => {
    const newTabsData = { ...tabsData };
    delete newTabsData[activeTab];
    setTabsData(newTabsData);
    const newTabKeys = tabKeys.filter((key) => key !== activeTab);
    setTabKeys(newTabKeys);
    setActiveTab(newTabKeys[0] || "0");
  };

  const handleSubmit = (newStatus) => {
    // De fält som ska sparas på högsta nivå
    const topLevelFields = [
      "SustEpdChecksum",
      "SustFinfoNumber",
      "SustEpdOwner",
      "SustEpdPublisher",
      "SusEpdLastUpdateDataImport",
      "SustEpdValidTo",
      "SustEpdId",
      "SustEpdStandard",
      "SustEpdComment",
      "SustBiogenEpd",
      "ExtractaCalculateGwp",
      "SustEpdFunctionalUnit",
      "SustExternalId",
      "SustExternalDataLink"
 
    ];

    // Separera datan i två delar: en för toppnivån och en för tabsData
    let topLevelData = {};
    topLevelFields.forEach((field) => {
      if (formData[field] !== undefined) {
        topLevelData[field] = formData[field];
      }
    });

    // Sätt den nya statusen och behåll övrig toppnivådata
    topLevelData["ExtractaStatus"] = newStatus;

    // Den uppdaterade datan som ska skickas
    const updatedData = {
      ...topLevelData,
      tabsData: tabsData, 
      UserName: user.email,
      UserEmail: user.name,
    };

    console.log("Submitting updated data:", updatedData);

    dispatch(
      updateProduct({ productId: formData.SustEpdChecksum, data: updatedData })
    )
      .unwrap()
      .then(() => {
        console.log("Update successful"); 
        setIsChanged(false);
        closeModal();
      })
      .catch((error) => {
        console.error("Error updating product:", error); // Loggar vid fel
        setShowAlert(true);
      });
  };

  const handleChange = (e, field) => {
    let value = e.target.value;

    // Ersätt kommatecken med punkter för att hantera decimalavskiljare
    value = value.replace(/,/g, '.');

    // Använd funktionell uppdatering för att säkerställa att vi arbetar med det senaste tillståndet
    setTabsData((prevTabsData) => {
      const updatedTabs = { ...prevTabsData };
      const updatedTabData = { ...updatedTabs[activeTab], [field]: value };

      updatedTabs[activeTab] = updatedTabData;

      return updatedTabs;
    });

    // Uppdatera changedFields för den aktiva tabben om nödvändigt
    setChangedFields((prevChangedFields) => {
      const updatedChangedFields = { ...prevChangedFields };
      updatedChangedFields.tabs[activeTab] = {
        ...(updatedChangedFields.tabs[activeTab] || {}),
        [field]: true,
      };

      return updatedChangedFields;
    });

    setIsChanged(true);
    // Om beräkning är beroende av detta fält, sätt shouldCalculate till true
    setShouldCalculate(true);
  };

  const handleCommonFieldChange = (e, field) => {
    const value = e.target.value;

    // Uppdatera formData med det nya värdet för det gemensamma fältet
    setFormData({
      ...formData,
      [field]: value,
    });

    // Uppdatera changedFields för att markera det gemensamma fältet som ändrat
    setChangedFields((prevChangedFields) => ({
      ...prevChangedFields,
      common: {
        ...prevChangedFields.common,
        [field]: true,
      },
    }));

    setIsChanged(true);
    setShouldCalculate(true);
  };

  useEffect(() => {
    if (shouldCalculate) {
      calculateSustEpdA1A3Calc();
      setShouldCalculate(false); // Återställ flaggan efter beräkningen
    }
  }, [shouldCalculate, tabsData, activeTab]); // Lyssna endast på shouldCalculate, men lägg till tabsData och activeTab som beroenden för att hantera uppdateringar korrekt

  function validateFields(tab) {
    // Kontrollera att alla nödvändiga fält har giltiga värden
    return tab.SustEpdGwpFossilA1A3 > 0 && tab.SustEpdGwpLulucA1A3 > 0 && tab.SustEpdGwpBiogenicA1A3 > 0;
  }

  const copyCalculatedDataToA1A3 = () => {
    setTabsData((prevTabsData) => {
      const updatedTabsData = { ...prevTabsData };
      const currentTabData = updatedTabsData[activeTab];
      const calculatedValue = currentTabData?.SustEpdA1A3Calc;

      if (calculatedValue > 0) {
        // Uppdatera SustEpdA1A3 direkt i tabsData
        updatedTabsData[activeTab] = {
          ...currentTabData,
          SustEpdA1A3: calculatedValue,
        };

        // Uppdatera formData för ExtractaCalculateGwp
        setFormData((prevFormData) => ({
          ...prevFormData,
          ExtractaCalculateGwp: true,
        }));

        // Markera fälten som ändrade
        setChangedFields((prevChangedFields) => ({
          ...prevChangedFields,
          tabs: {
            ...prevChangedFields.tabs,
            [activeTab]: {
              ...prevChangedFields.tabs[activeTab],
              SustEpdA1A3: true,
            },
          },
          common: {
            ...prevChangedFields.common,
            ExtractaCalculateGwp: true,
          },
        }));

        setIsChanged(true);
      }
      return updatedTabsData;
    });
  };

  // Beräkna värde
  const calculateSustEpdA1A3Calc = () => {
    const tab = tabsData[activeTab];
    if (formData.SustEpdStandard !== "EN 15804:2012+A2:2019") {
      return;
    }
  
    const gwpFossil = parseFloat(tab.SustEpdGwpFossilA1A3) || 0;
    const gwpLuluc = parseFloat(tab.SustEpdGwpLulucA1A3) || 0;
    let gwpBiogenic = parseFloat(tab.SustEpdGwpBiogenicA1A3) || 0;
    gwpBiogenic = gwpBiogenic > 0 ? gwpBiogenic : 0; // Om biogent värde är negativt, anta att det är 0


    // Kontrollera att gwpFossil och gwpLuluc är större än 0 för att utföra beräkningen
    if (gwpFossil > 0 && gwpLuluc > 0) {
      const sum = gwpFossil + gwpLuluc + gwpBiogenic; // Inkludera gwpBiogenic i summan även om det är 0

      // Uppdatera direkt tabsData med det nya beräknade värdet
      setTabsData(prevTabsData => {
        const newTabsData = { ...prevTabsData };
        const newTabData = { ...newTabsData[activeTab], SustEpdA1A3Calc: sum.toString() };
        newTabsData[activeTab] = newTabData;
        return newTabsData;
      });
    } else {
      // Om du vill sätta SustEpdA1A3Calc till null när beräkningen inte utförs, avkommentera nästa rad
      setTabsData(prevTabsData => ({ ...prevTabsData, [activeTab]: { ...prevTabsData[activeTab], SustEpdA1A3Calc: null } }));
    }
  };

  const generateSustExternalDataLink = () => {
    const newLink = `${formData.ExtractaExternalDataLinkProvider}${formData.SustExternalId}?format=xml`;

    // Uppdatera formData med det nya värdet för SustExternalDataLink
    setFormData((prevFormData) => ({
      ...prevFormData,
      SustExternalDataLink: newLink,
    }));

    // Markera fältet som ändrat
    setChangedFields((prevChangedFields) => ({
      ...prevChangedFields,
      common: {
        ...prevChangedFields.common,
        SustExternalDataLink: true,
      },
    }));

    setIsChanged(true);
  };

  useEffect(() => {
    if (formData.ExtractaExternalDataLinkProvider && formData.SustExternalId) {
      generateSustExternalDataLink();
    }
  }, [formData.ExtractaExternalDataLinkProvider, formData.SustExternalId]);

  const handleClose = () => {
    if (!isChanged) {
      closeModal();
    } else {
      if (
        window.confirm(
          "Du har osparade ändringar. Är du säker på att du vill lämna?"
        )
      ) {
        closeModal();
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="xl"
      backdrop="static"
      keyboard={false}
    >
      {showAlert && (
        <Alert variant="danger" onClose={handleAlertClose} dismissible>
          <Alert.Heading>Fel!</Alert.Heading>
          <p>
            Systemet misslyckades med att spara {activeProduct.SustEpdChecksum},
            vänligen försök igen.
          </p>
        </Alert>
      )}

      <ProductModalHeader product={activeProduct} handleSubmit={handleSubmit} />

      <Modal.Body>
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
          {tabKeys.map((key, index) => (
            <Tab eventKey={key} title={`Dataset ${index + 1}`} key={key}>
              <ProductForm
                formData={formData}
                tabsData={tabsData}
                activeTab={activeTab}
                handleChange={handleChange}
                handleCommonFieldChange={handleCommonFieldChange}
                changedFields={changedFields}
                copyCalculatedDataToA1A3={copyCalculatedDataToA1A3}
              />
            </Tab>
          ))}
        </Tabs>

        <Image
          src={`${activeProduct.SustAssetLinkEpd}/preview/${activeProduct.SustEpdChecksum}.jpg`}
          alt="Produktbild"
          rounded
          style={{
            position: "absolute",
            top: "3%",
            right: "4%",
            zIndex: 2,
            width: "290px",
            height: "auto",
            objectFit: "contain",
          }}
        />
      </Modal.Body>

      <ProductModalFooter
        addTab={addTab}
        removeTab={removeTab}
        tabKeys={tabKeys}
        activeTab={activeTab}
        product={activeProduct}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    </Modal>
  );
}

export default ProductModal;
