import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { statusLabelMap } from "../../config/statusConfig"; // Updated import

const ProductModalHeader = ({ handleSubmit }) => {
  const activeProduct = useSelector((state) => state.epd.activeProduct);

  const status = activeProduct?.ExtractaStatus || 'new';

  return (
    <Modal.Header closeButton>
      <Modal.Title>
        Redigera produkt -{' '}
        {activeProduct?.tabsData?.[0]?.SustEpdProductName?.length > 40
          ? `${activeProduct.tabsData[0].SustEpdProductName.substring(0, 40)}...`
          : activeProduct?.tabsData?.[0]?.SustEpdProductName}
        {activeProduct?.tabsData?.[0]?.SustEpdProductName ? ' - ' : ''}{statusLabelMap[status] || "Okänd"}
      </Modal.Title>
    </Modal.Header>
  );
};

export default ProductModalHeader;
