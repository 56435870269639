import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../common/assets/images/logo.png';

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect();
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100">
      <img src={logo} alt="extracta" className="mb-4" width="300" height="260" />
      <button className="btn btn-dark btn-lg" onClick={handleLogin}>
        Logga in
      </button>
    </div>
  );
};

export default LoginPage;
