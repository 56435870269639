import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Navbar, Nav, Image, NavDropdown } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import LogoutButton from "../LogoutButton";

const CustomNavbar = () => {
  const { counts: statusCounts } = useSelector((state) => state.epdStatusCounts);
  const userRole = useSelector((state) => state.user.role);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setIsAdmin(userRole?.includes('extracta_admin'));
  }, [userRole]);



  return (
    <Navbar
      bg="light"
      expand="lg"
      style={{
        height: "70px",
        fontSize: "18px",
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <Link
        to="/portal"
        className="navbar-brand d-flex align-items-center"
        style={{ paddingLeft: "20px" }}
      >
        <Image
          src={logo}
          alt="Extracta"
          width="70"
          height="60"
          className="mr-3"
        />
        <div
          className="d-flex flex-column align-items-start"
          style={{ lineHeight: "1", marginTop: "5px", marginLeft: "5px" }}
        >
          <span
            style={{
              fontSize: "16px",
              color: "black",
              display: "block",
              marginBottom: "0",
              fontWeight: "bold",
            }}
          >
            FINFO
          </span>
          <span style={{ fontSize: "32px", marginTop: "-8px" }}>extracta</span>
        </div>
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-between"
      >
        <Nav>
          <NavDropdown title="Klimat" id="nav-dropdown-klimat">
            <NavDropdown.Item as={Link} to="/portal/extract-epd?filter=new">
              Extrahera klimatdata &nbsp;&nbsp;
              {statusCounts.new + statusCounts.edit > 0 && (
                <span
                  className="ml-3"
                  style={{
                    color: "#fff",
                    backgroundColor: "#198754",
                    borderRadius: "0.25rem",
                    padding: "0.3em 0.5em",
                    fontSize: "75%",
                  }}
                >
                  {statusCounts.new + statusCounts.edit}
                </span>
              )}
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/portal/extract-epd?filter=validation"
            >
              Validera klimatdata &nbsp;&nbsp;
              {statusCounts.validation > 0 && (
                <span
                  className="ml-3"
                  style={{
                    color: "#fff",
                    backgroundColor: "#198754",
                    borderRadius: "0.25rem",
                    padding: "0.3em 0.5em",
                    fontSize: "75%",
                  }}
                >
                  {statusCounts.validation}
                </span>
              )}
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/portal/product-search">
              Sök i klimatdatabasen
            </NavDropdown.Item>
            {isAdmin && (
              <NavDropdown.Item as={Link} to="/portal/export-epd">
                Exportera klimatdata
              </NavDropdown.Item>
            )}
            {/* {isAdmin && (
            <NavDropdown.Item as={Link} to="/portal/calculate-data">
              Utför klimatberäkningar
            </NavDropdown.Item>
            )} */}
          </NavDropdown>
          <NavDropdown title="Hållbarhet" id="nav-dropdown-hallbarhet">
            <NavDropdown.Item as={Link} to="/portal/synchronize-data">
              Admin
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/portal/extract-sds">
              Extrahera data från säkerhetsdatablad
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <div style={{ paddingRight: "20px" }}>
          <LogoutButton />
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
