import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const ProductModalFooter = ({
  addTab,
  removeTab,
  tabKeys,
  activeTab,
  handleSubmit,
  handleClose,
}) => {
  const activeProduct = useSelector((state) => state.epd.activeProduct);

  return (
    <Modal.Footer>
      <Button variant="secondary" onClick={addTab}>
        Lägg till dataset
      </Button>
      {tabKeys.length > 1 && activeTab !== tabKeys[0] && (
        <Button variant="danger" onClick={removeTab}>
          Ta bort aktivt dataset
        </Button>
      )}
      {(!activeProduct.ExtractaStatus ||
        activeProduct.ExtractaStatus === "edit" ||
        activeProduct.ExtractaStatus === "new") && (
        <Button
          variant="success"
          onClick={() => {
            handleSubmit("edit");
          }}
        >
          Spara och fortsätt senare
        </Button>
      )}
      {["export", "exported", "validation"].includes(activeProduct.ExtractaStatus) && (
        <Button
          variant="success"
          onClick={() => {
            handleSubmit("edit");
          }}
        >
          Skicka tillbaka till datainhämtning
        </Button>
      )}
      {(!activeProduct.ExtractaStatus ||
        activeProduct.ExtractaStatus === "edit" ||
        activeProduct.ExtractaStatus === "new") && (
        <Button
          variant="success"
          onClick={() => {
            handleSubmit("validation");
          }}
        >
          Klar för validering
        </Button>
      )}
      {activeProduct.ExtractaStatus === "validation" && (
        <Button
          variant="success"
          onClick={() => {
            handleSubmit("export");
          }}
        >
          Validerad
        </Button>
      )}
      <Button variant="secondary" onClick={handleClose}>
        Stäng
      </Button>
    </Modal.Footer>
  );
};

export default ProductModalFooter;
