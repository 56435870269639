import React, { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faGasPump,
  faBiohazard,
  faEarthEurope,
} from "@fortawesome/free-solid-svg-icons";

const roundToTenDecimals = (num) => {
  return Math.round(num * 10000000000) / 10000000000;
};

const UsageStage = ({ tabsData, activeTab, handleChange, changedFields }) => {
  const stages = ['B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7'];
  const fields = ['Total', 'Fossil', 'Biogenic', 'Luluc'];
  const icons = [faCalculator, faGasPump, faBiohazard, faEarthEurope];
  const sumFields = ['B1B3B5B7', 'B1B5', 'B1B7'];

  useEffect(() => {
    calculateSums();
  }, [tabsData, activeTab]);

  const calculateSums = () => {
    fields.forEach(field => {
      let sumB1B3B5B7 = 0;
      let sumB1B5 = 0;
      let sumB1B7 = 0;
      let allFieldsFilled = true;

      stages.forEach(stage => {
        const value = parseFloat(tabsData?.[activeTab]?.[`SustEpdGwp${field}${stage}`]?.replace(',', '.'));
        if (isNaN(value)) {
          allFieldsFilled = false;
        } else {
          if (['B1', 'B2', 'B3', 'B5', 'B6', 'B7'].includes(stage)) {
            sumB1B3B5B7 = roundToTenDecimals(sumB1B3B5B7 + value);
          }
          if (['B1', 'B2', 'B3', 'B4', 'B5'].includes(stage)) {
            sumB1B5 = roundToTenDecimals(sumB1B5 + value);
          }
          sumB1B7 = roundToTenDecimals(sumB1B7 + value);
        }
      });

      if (allFieldsFilled) {
        updateSumField(field, 'B1B3B5B7', sumB1B3B5B7);
        updateSumField(field, 'B1B5', sumB1B5);
        updateSumField(field, 'B1B7', sumB1B7);
      }
    });
  };

  const updateSumField = (field, sumField, sum) => {
    const fieldName = `SustEpdGwp${field}${sumField}`;
    const currentValue = parseFloat(tabsData?.[activeTab]?.[fieldName]?.replace(',', '.'));
    if (isNaN(currentValue) || Math.abs(currentValue - sum) > 0.0000000001) {
      handleChange({ target: { value: sum.toString() } }, fieldName, false);
    }
  };

  const formatValue = (value) => {
    if (value === undefined || value === null || value === "") return "";
    // Behåll ursprunglig inmatning om det är en giltig sträng
    if (typeof value === 'string' && /^-?\d*[.,]?\d*$/.test(value)) {
      return value;
    }
    const floatValue = parseFloat(value.toString().replace(',', '.'));
    if (isNaN(floatValue)) return value;
    // Formatera numret med komma som decimaltecken
    return floatValue.toString().replace('.', ',').replace(/,?0+$/, '');
  };

  const getSumFieldName = (sumField) => {
    const sumFieldNames = {
      'B1B3B5B7': 'B1-B3+B5-B7',
      'B1B5': 'B1-B5',
      'B1B7': 'B1-B7',
    };
    return sumFieldNames[sumField] || sumField;
  };

  const renderFormGroup = (stage, field, icon) => (
    <Form.Group className="custom-form-group">
      <Form.Label className="custom-label">
        <FontAwesomeIcon icon={icon} /> {stage} GWP {field}
      </Form.Label>
      <Form.Control
        type="number"
        step="any"
        value={formatValue(tabsData?.[activeTab]?.[`SustEpdGwp${field}${stage}`])}
        onChange={(e) => handleChange(e, `SustEpdGwp${field}${stage}`)}
        style={{
          backgroundColor: changedFields.tabs?.[activeTab]?.[`SustEpdGwp${field}${stage}`]
            ? "#e1f7ea"
            : "",
        }}
      />
    </Form.Group>
  );

  return (
    <>
      <Row className="mb-2">
        <Col>
          <h5>Klimatvärden - Användningsskede</h5>
        </Col>
      </Row>
      {fields.map((field, fieldIndex) => (
        <React.Fragment key={field}>
          {stages.reduce((rows, stage, index) => {
            if (index % 4 === 0) rows.push([]);
            rows[rows.length - 1].push(
              <Col key={`${field}-${stage}`}>
                {renderFormGroup(stage, field, icons[fieldIndex])}
              </Col>
            );
            return rows;
          }, []).map((row, rowIndex) => (
            <Row key={`${field}-row-${rowIndex}`}>
              {row}
              {row.length < 4 && <Col></Col>}
            </Row>
          ))}
          <Row>
            {sumFields.map(sumField => (
              <Col key={`${field}-${sumField}`}>
                <Form.Group className="custom-form-group">
                  <Form.Label className="custom-label">
                    <FontAwesomeIcon icon={icons[fieldIndex]} /> {getSumFieldName(sumField)} GWP {field}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    step="any"
                    value={formatValue(tabsData?.[activeTab]?.[`SustEpdGwp${field}${sumField}`])}
                    onChange={(e) => handleChange(e, `SustEpdGwp${field}${sumField}`)}
                    style={{
                      backgroundColor: changedFields.tabs?.[activeTab]?.[`SustEpdGwp${field}${sumField}`]
                        ? "#e1f7ea"
                        : "",
                    }}
                  />
                </Form.Group>
              </Col>
            ))}
            <Col></Col>
          </Row>
          <Row className="mb-4"></Row>
        </React.Fragment>
      ))}
    </>
  );
};

export default UsageStage;