import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Badge } from 'react-bootstrap';
import './ProductCard.css';
import backupImage from '../assets/images/noImage.webp';
import { statusLabelMap } from '../config/statusConfig'; // Updated import
import { setActiveProduct, setShowModal } from "../features/epdProducts/epdReducer";

function ProductCard({ product }) {
  const dispatch = useDispatch();

  const nestedProduct = product?.tabsData?.['0'];
  const assetLink = product.SustAssetLinkEpd;
  const epdId = product.SustEpdChecksum;
  const productName = nestedProduct?.SustEpdProductName;
  const status = product.ExtractaStatus;
  const displayStatus = statusLabelMap[status.toLowerCase()] || "Okänd"; // Use statusLabelMap here

  useEffect(() => {
    console.log(`ProductCard renderad för ${epdId}`);
  });

  const openModal = () => {
    // console.log("Setting active product (ProductCard):", product);
    dispatch(setActiveProduct(product));
    dispatch(setShowModal(true)); 
  };

  return (
    <div style={{ position: 'relative' }}>
      <Badge className="Badge" bg="secondary">
        {displayStatus.split('\n').map((str, idx) => 
          <span key={`${product.SustEpdChecksum}-status-${idx}`}>
            {str}
            {idx !== displayStatus.split('\n').length - 1 && <br />}
          </span>
        )}
      </Badge>

      <Card className="Card mt-3" onClick={openModal} style={{ cursor: 'pointer' }}> 
        <Card.Img 
          variant="top" 
          className="Card-Img" 
          src={`${assetLink}/preview/${epdId}.jpg` || backupImage}
        />
        <Card.Body className="Card-Body">
          <Card.Title className="Card-Title">{epdId}</Card.Title>
          <Card.Text className="Card-Text">
            {productName}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProductCard;
