import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ProductCard from "./ProductCard";
import ProductModal from "./ProductModal/ProductModal";
import "./ProductList.css";
import { fetchProducts } from "../features/epdProducts/epdProductSlice";
import { setShowModal } from "../features/epdProducts/epdReducer";
import { fetchEpdStatusCounts } from "../features/epdProducts/epdStatusCountsSlice";

function ProductList() {
  const { search } = useLocation();
  const products = useSelector((state) => state.epdProducts.products);
  const showModal = useSelector((state) => state.epd.showModal);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const getFilterFromURL = () => new URLSearchParams(search).get("filter");

  const applyFilter = (filter) => {
    if (!products || products.length === 0) return;

    const filtered = statusFilters[filter]
      ? products.filter(statusFilters[filter])
      : products;

    setFilteredProducts(filtered);
    setCurrentItems(filtered);
  };

  // Hantera filtrering baserat på URL-parametern
  useEffect(() => {
    const filter = getFilterFromURL();
    applyFilter(filter);
  }, [products, search]);

  const statusFilters = {
    new: (product) => product.ExtractaStatus === "new" || product.ExtractaStatus === "edit",
    edit: (product) => product.ExtractaStatus === "edit",
    validation: (product) => product.ExtractaStatus === "validation",
    export: (product) => product.ExtractaStatus === "export",
    exported: (product) => product.ExtractaStatus === "exported",
  };

  const dispatch = useDispatch();

  const closeModal = () => {
    // console.log("Closing modal");

    dispatch(setShowModal(false));
    dispatch(fetchProducts());
    dispatch(fetchEpdStatusCounts());
  };

  return (
    <Container className="product-list-container">
      <Row>
        {currentItems.map((product, index) => (
          <Col xs={6} md={3} key={product.SustEpdChecksum || index}>
            <ProductCard product={product} />
          </Col>
        ))}
      </Row>

      {showModal && <ProductModal closeModal={closeModal} />}
    </Container>
  );
}

export default ProductList;
