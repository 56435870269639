// store.js
import { configureStore } from '@reduxjs/toolkit';
import epdProductReducer from '../epd/features/epdProducts/epdProductSlice';
import epdStatusCountsReducer from '../epd/features/epdProducts/epdStatusCountsSlice.js';
import epdReducer from '../epd/features/epdProducts/epdReducer.js';
import userReducer from '../common/features/userReducer';

export const store = configureStore({
  reducer: {
    epdProducts: epdProductReducer,
    epdStatusCounts: epdStatusCountsReducer,
    user: userReducer,
    epd: epdReducer
  },
});
