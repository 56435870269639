import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator, faGasPump, faBiohazard, faEarthEurope } from "@fortawesome/free-solid-svg-icons";

const BenefitsAndLoads = ({ tabsData, activeTab, handleChange, changedFields }) => {
  return (
    <>
      <Row className="mb-2">
        <Col>
          <h5>Klimatvärden - Fördelar och belastningar utanför systemgränsen</h5>
        </Col>
      </Row>
      <Row>
        <Row> 
          <Col>
            {" "}
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-label">
                <FontAwesomeIcon icon={faCalculator} /> D GWP Total
              </Form.Label>
              <Form.Control
                type="number"
                step="any" // Tillåter decimaler
                pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
                value={tabsData?.[activeTab]?.SustEpdGwpTotalD || ""}
                onChange={(e) => handleChange(e, "SustEpdGwpTotalD")}
                style={{
                  backgroundColor: changedFields.tabs?.[activeTab]
                    ?.SustEpdGwpTotalD
                    ? "#e1f7ea"
                    : "",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            {" "}
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-label">
                <FontAwesomeIcon icon={faGasPump} /> D GWP Fossil
              </Form.Label>
              <Form.Control
                type="number"
                step="any" // Tillåter decimaler
                pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
                value={tabsData?.[activeTab]?.SustEpdGwpFossilD || ""}
                onChange={(e) => handleChange(e, "SustEpdGwpFossilD")}
                style={{
                  backgroundColor: changedFields.tabs?.[activeTab]
                    ?.SustEpdGwpFossilD
                    ? "#e1f7ea"
                    : "",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            {" "}
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-label">
                <FontAwesomeIcon icon={faBiohazard} /> D GWP Biogenic
              </Form.Label>
              <Form.Control
                type="number"
                step="any" // Tillåter decimaler
                pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
                value={tabsData?.[activeTab]?.SustEpdGwpBiogenicD || ""}
                onChange={(e) => handleChange(e, "SustEpdGwpBiogenicD")}
                style={{
                  backgroundColor: changedFields.tabs?.[activeTab]
                    ?.SustEpdGwpBiogenicD
                    ? "#e1f7ea"
                    : "",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            {" "}
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-label">
                <FontAwesomeIcon icon={faEarthEurope} /> D GWP Luluc
              </Form.Label>
              <Form.Control
                type="number"
                step="any" // Tillåter decimaler
                pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
                value={tabsData?.[activeTab]?.SustEpdGwpLulucD || ""}
                onChange={(e) => handleChange(e, "SustEpdGwpLulucD")}
                style={{
                  backgroundColor: changedFields.tabs?.[activeTab]
                    ?.SustEpdGwpLulucD
                    ? "#e1f7ea"
                    : "",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default BenefitsAndLoads;