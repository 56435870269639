import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Container,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import { fetchProducts } from "../features/epdProducts/epdProductSlice";
import "./ExportExcelEpd.css";

function ExportExcelEpd() {
  const dispatch = useDispatch();
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const [isDownloadingMultiple, setIsDownloadingMultiple] = useState(false);

  const products = useSelector(state => state.epdProducts.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const eligibleProductsCount = products.filter(product => product.ExtractaStatus === 'export' && Object.keys(product.tabsData).length > 0).length;
  const canExportMultiple = eligibleProductsCount > 0;

  const handleExportToExcel = async (exportType) => {
    if (exportType === "all") {
      setIsDownloadingAll(true);
    } else if (exportType === "multiple") {
      setIsDownloadingMultiple(true);
    }

    try {
      let endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/epd/exportExcel/${exportType}`;
      const response = await axios.get(endpoint, { responseType: "blob" });

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "extracta_exported_data.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error exporting Excel: ", error);
    } finally {
      if (exportType === "all") {
        setIsDownloadingAll(false);
      } else if (exportType === "multiple") {
        setIsDownloadingMultiple(false);
      }
    }
  };

  return (
    <Container>
      <div className="cardContainerSecond">
        <div className="leftColumnSecond">
          <h3>Exportera</h3>
          <p>
            Här kan du plocka ut en fil för manuell import till inRiver. Den
            innehåller både hållbarhetsartiklar med enkla och multipla dataset.
          </p>
          <br />
          <Button
            variant="success"
            disabled={isDownloadingAll || isDownloadingMultiple}
            onClick={() => handleExportToExcel("all")}
            className="bottomButtons"
          >
            {isDownloadingAll ? (
              <>
                Skapar fil... <Spinner animation="border" size="sm" />
              </>
            ) : (
              "Exportera alla dataset"
            )}
          </Button>
          {' '}
          <Button
            variant="success"
            disabled={isDownloadingAll || isDownloadingMultiple || !canExportMultiple}
            onClick={() => handleExportToExcel("multiple")}
            className="bottomButtons"
          >
            {isDownloadingMultiple ? (
              <>
                Skapar fil... <Spinner animation="border" size="sm" />
              </>
            ) : (
              `Exportera multipla dataset (${eligibleProductsCount})`
            )}
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default ExportExcelEpd;
