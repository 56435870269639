import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator, faGasPump, faBiohazard, faEarthEurope } from "@fortawesome/free-solid-svg-icons";

const BenefitsAndLoads = ({ tabsData, activeTab, handleChange, changedFields }) => {
  return (
    <>

      <Row>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              <FontAwesomeIcon icon={faCalculator} /> A1-A3 GWP Total
            </Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdGwpTotalA1A3 || ""}
              onChange={(e) => handleChange(e, "SustEpdGwpTotalA1A3")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]
                  ?.SustEpdGwpTotalA1A3
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              <FontAwesomeIcon icon={faCalculator} /> A4 GWP Total
            </Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdGwpTotalA4 || ""}
              onChange={(e) => handleChange(e, "SustEpdGwpTotalA4")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]
                  ?.SustEpdGwpTotalA4
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          {" "}
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              <FontAwesomeIcon icon={faCalculator} /> A5 GWP Total
            </Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdGwpTotalA5 || ""}
              onChange={(e) => handleChange(e, "SustEpdGwpTotalA5")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]
                  ?.SustEpdGwpTotalA5
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              <FontAwesomeIcon icon={faGasPump} /> A1-A3 GWP Fossil
            </Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdGwpFossilA1A3 || ""}
              onChange={(e) => {
                handleChange(e, "SustEpdGwpFossilA1A3");
                // calculateSustEpdA1A3Calc();
              }}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]
                  ?.SustEpdGwpFossilA1A3
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          {" "}
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              <FontAwesomeIcon icon={faGasPump} /> A4 GWP Fossil
            </Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdGwpFossilA4 || ""}
              onChange={(e) => handleChange(e, "SustEpdGwpFossilA4")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]
                  ?.SustEpdGwpFossilA4
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          {" "}
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              <FontAwesomeIcon icon={faGasPump} /> A5 GWP Fossil
            </Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdGwpFossilA5 || ""}
              onChange={(e) => handleChange(e, "SustEpdGwpFossilA5")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]
                  ?.SustEpdGwpFossilA5
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              <FontAwesomeIcon icon={faBiohazard} /> A1-A3 GWP Biogenic
            </Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdGwpBiogenicA1A3 || ""}
              onChange={(e) => {
                handleChange(e, "SustEpdGwpBiogenicA1A3");
                // calculateSustEpdA1A3Calc(); // Anropa beräkningsfunktionen
              }}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]
                  ?.SustEpdGwpBiogenicA1A3
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          {" "}
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              <FontAwesomeIcon icon={faBiohazard} /> A4 GWP Biogenic
            </Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdGwpBiogenicA4 || ""}
              onChange={(e) => handleChange(e, "SustEpdGwpBiogenicA4")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]
                  ?.SustEpdGwpBiogenicA4
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          {" "}
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              <FontAwesomeIcon icon={faBiohazard} /> A5 GWP Biogenic
            </Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdGwpBiogenicA5 || ""}
              onChange={(e) => handleChange(e, "SustEpdGwpBiogenicA5")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]
                  ?.SustEpdGwpBiogenicA5
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              <FontAwesomeIcon icon={faEarthEurope} /> A1-A3 GWP Luluc
            </Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdGwpLulucA1A3 || ""}
              onChange={(e) => {
                handleChange(e, "SustEpdGwpLulucA1A3");
                // calculateSustEpdA1A3Calc(); // Anropa beräkningsfunktionen
              }}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]
                  ?.SustEpdGwpLulucA1A3
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          {" "}
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              <FontAwesomeIcon icon={faEarthEurope} /> A4 GWP Luluc
            </Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdGwpLulucA4 || ""}
              onChange={(e) => handleChange(e, "SustEpdGwpLulucA4")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]
                  ?.SustEpdGwpLulucA4
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          {" "}
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              <FontAwesomeIcon icon={faEarthEurope} /> A5 GWP Luluc
            </Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdGwpLulucA5 || ""}
              onChange={(e) => handleChange(e, "SustEpdGwpLulucA5")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]
                  ?.SustEpdGwpLulucA5
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      
    </>
  );
};

export default BenefitsAndLoads;