import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from 'react-bootstrap';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Laddar...</span>
        </Spinner>
      </div>
    );
  }

  if (!isAuthenticated) {
    // Omdirigera till inloggningssidan om inte autentiserad
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
