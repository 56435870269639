import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import Navbar from "../../common/components/navbar/Navbar";
import ExportExcelEpd from "../../epd/pages/ExportExcelEpd";
import ExtractEpd from "../../epd/pages/ExtractEpd";
import ExtractSds from "../../sds/pages/ExtractSds";
import ProductSearch from "../../epd/components/ProductSearch";
import { fetchEpdStatusCounts } from "../../epd/features/epdProducts/epdStatusCountsSlice";
import { useAuth0 } from "@auth0/auth0-react";
import "./Portal.css";
import a1a5 from "../../common/assets/images/a1a5.webp";
import finfo from "../../common/assets/images/finfo.png";
import { useLocation } from "react-router-dom";
import { setUserRole } from '../features/userActions.js';

const Portal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const isMainPortalPage = location.pathname === "/portal";
  const { user, isAuthenticated } = useAuth0();


  useEffect(() => {
    if (isAuthenticated && user) {
      const userRole = user['https://extracta.se/roles'];
      dispatch(setUserRole(userRole));
    }
  }, [isAuthenticated, user, dispatch]);

  useEffect(() => {
    dispatch(fetchEpdStatusCounts());
    const exchangeCodeForToken = async () => {
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        try {
          await getAccessTokenSilently();
          navigate("/portal/", { replace: true });
        } catch (err) {
          console.error(err);
        }
      }
    };

    exchangeCodeForToken();
  }, [dispatch, getAccessTokenSilently, navigate]);

  return (
    <div>
      <Navbar />
      <Container fluid>
        {isMainPortalPage && (
          <div className="portalContainer" style={{ paddingTop: "20px" }}>
            <div className="d-flex flex-column justify-content-center align-items-center margin-">
              <img
                src={finfo}
                alt="extracta"
                className="mb-4"
                width="300"
              />
              <h3>extracta 2.00</h3>
              <p></p>
              <p>
                Den här lösningen är byggd för att förenkla extraktion av data
                från elektroniska dokument samt som ett gränssnitt för
                relaterade funktioner.
              </p>
            </div>
            <div className="portalCardContainer col-md-12">
              <h3>Finfo Klimat</h3>
              <p>
                Klimatdeklarationer till Boverket är avgörande för att minska
                byggsektorns klimatpåverkan. De utgör en del av Sveriges
                ansträngningar att förstå och minska miljöpåverkan från
                byggprodukter.
              </p>
  
              <h5>Vad är en EPD?</h5>
              <p>
                En EPD (Environmental Product Declaration) är ett standardiserat
                dokument som beskriver en produkts miljöpåverkan över dess
                livscykel.
              </p>
  
              <h5>Förstå GWP (Global Warming Potential)</h5>
              <ul>
                <li>
                  <strong>GWP Fossil:</strong> Växthusgasutsläpp från fossila
                  källor.
                </li>
                <li>
                  <strong>GWP LULUC:</strong> Påverkan från markanvändning och
                  markanvändningsförändringar.
                </li>
                <li>
                  <strong>GWP Biogenic:</strong> Påverkan från biogena källor,
                  såsom kol bundet i biomassa.
                </li>
                <li>
                  <strong>GWP Total:</strong> Totala växthusgasutsläpp under en
                  produkts livscykel.
                </li>
              </ul>
  
              <h4>Klimatpåverkan i olika produktskeden</h4>
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <h5>
                    A1-A3: Råvaruförsörjning, transport och tillverkning
                  </h5>
                  <ul>
                    <li>
                      Klimatpåverkan till följd av råvaruförsörjning,
                      transport och tillverkning i produktskedet för bärande
                      konstruktionsdelar, klimatskärm och innerväggar.
                    </li>
                    <li>Övriga byggprodukter i byggnaden.</li>
                  </ul>
  
                  <h5>A4: Transport</h5>
                  <ul>
                    <li>
                      Klimatpåverkan till följd av transporter av ingående
                      byggprodukter i bärande konstruktionsdelar, klimatskärm
                      och innerväggar, från tillverkningsfabrik till
                      byggarbetsplatsen.
                    </li>
                    <li>
                      Övriga transporter, inklusive transport av
                      arbetsmaskiner och bodar till och från arbetsplatsen
                      samt transport av förbrukningsmaterial.
                    </li>
                  </ul>
  
                  <h5>A5: Byggspill</h5>
                  <ul>
                    <li>
                      Klimatpåverkan från produktskede och transport till
                      byggarbetsplatsen av komponenter som blir till spill på
                      byggarbetsplatsen.
                    </li>
                    <li>
                      Klimatpåverkan från transport till
                      avfallshanteringsplats och avfallshantering av
                      byggspill.
                    </li>
                    <li>
                      Klimatpåverkan från tillverkning och hantering av
                      emballage och annat avfall.
                    </li>
                  </ul>
                </div>
                <img
                  src={a1a5}
                  alt="extracta"
                  className="ml-auto mb-4"
                  width="470"
                  height="400"
                />
              </div>
              <h5>A5: Energi</h5>
              <ul>
                <li>
                  Klimatpåverkan från all användning av el, värme och bränslen
                  på byggarbetsplatsen, exempelvis för byggarbetsplatsens
                  fordon, maskiner och verktyg.
                </li>
                <li>
                  Uppvärmning och drift (inklusive ventilation, belysning,
                  hiss och liknande) av tillfälliga bodar, kontor, förråd och
                  andra byggnader inklusive byggnaden under produktion.
                </li>
                <li>Energi och bränsle för markarbete.</li>
              </ul>
  
              <h5>A5: Övrigt</h5>
              <ul>
                <li>
                  Vattenanvändning på byggarbetsplatsen för kylning av
                  byggmaskiner eller rengöring.
                </li>
                <li>
                  Hjälpmaterial som använts men inte ingår i byggproduktens
                  miljövarudeklaration. Nödvändiga tillfälliga arbeten.
                </li>
              </ul>
            </div>
          </div>
        )}
  
        <Routes>
          <Route path="/export-epd" element={<ExportExcelEpd />} />
          <Route path="/extract-epd" element={<ExtractEpd />} />
          <Route path="/extract-sds" element={<ExtractSds />} />
          <Route path="/product-search" element={<ProductSearch />} />
        </Routes>
      </Container>
    </div>
  );
};

export default Portal;
