import { createSlice } from '@reduxjs/toolkit';

const epdReducer = createSlice({
  name: 'epd',
  initialState: {
    products: [],
    loading: false,
    error: null,
    activeProduct: null,  
    showModal: false,    
    updateStatus: 'idle',
    isSearchActive: false,
  },
  reducers: {
    setActiveProduct: (state, action) => {
      state.activeProduct = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setIsSearchActive: (state, action) => { 
      state.isSearchActive = action.payload;
    }
  },
  extraReducers: (builder) => {
    // Inga extra reducers just nu
  },
});

export const { setActiveProduct, setShowModal, setIsSearchActive } = epdReducer.actions;

export default epdReducer.reducer;
