import React, { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faGasPump,
  faBiohazard,
  faEarthEurope,
} from "@fortawesome/free-solid-svg-icons";

const EndStage = ({ tabsData, activeTab, handleChange, changedFields }) => {
  const stages = ['C1', 'C2', 'C3', 'C4'];
  const types = ['Total', 'Fossil', 'Biogenic', 'Luluc'];

  useEffect(() => {
    calculateSums();
  }, [tabsData, activeTab]);

  const roundToTenDecimals = (num) => {
    return Math.round(num * 10000000000) / 10000000000;
  };

  const calculateSums = () => {
    types.forEach(type => {
      let sum = 0;
      let allFieldsFilled = true;
      
      stages.forEach(stage => {
        const value = parseFloat(tabsData?.[activeTab]?.[`SustEpdGwp${type}${stage}`]?.replace(',', '.'));
        if (isNaN(value)) {
          allFieldsFilled = false;
        } else {
          sum = roundToTenDecimals(sum + value);
        }
      });

      if (allFieldsFilled) {
        updateSumField(type, sum);
      }
    });
  };

  const updateSumField = (type, sum) => {
    const fieldName = `SustEpdGwp${type}C1C4`;
    const currentValue = parseFloat(tabsData?.[activeTab]?.[fieldName]?.replace(',', '.'));
    if (isNaN(currentValue) || Math.abs(currentValue - sum) > 0.0000000001) {
      handleChange({ target: { value: sum.toString() } }, fieldName);
    }
  };

  const formatValue = (value) => {
    if (value === undefined || value === null || value === "") return "";
    // Behåll ursprunglig inmatning om det är en giltig sträng
    if (typeof value === 'string' && /^-?\d*[.,]?\d*$/.test(value)) {
      return value;
    }
    const floatValue = parseFloat(value.toString().replace(',', '.'));
    if (isNaN(floatValue)) return value;
    // Formatera numret med komma som decimaltecken och behåll endast signifikanta decimaler
    return floatValue.toString().replace('.', ',').replace(/,?0+$/, '');
  };

  return (
    <>
      <Row className="mb-2">
        <Col>
          <h5>Klimatvärden - Slutskede</h5>
        </Col>
      </Row>
      {types.map(type => (
        <React.Fragment key={type}>
          <Row>
            {stages.map((stage) => (
              <Col key={`${type}-${stage}`}>
                <Form.Group className="custom-form-group">
                  <Form.Label className="custom-label">
                    <FontAwesomeIcon icon={getIcon(type)} /> {stage} GWP {type}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    step="any"
                    value={formatValue(tabsData?.[activeTab]?.[`SustEpdGwp${type}${stage}`])}
                    onChange={(e) => handleChange(e, `SustEpdGwp${type}${stage}`)}
                    style={{
                      backgroundColor: changedFields.tabs?.[activeTab]?.[`SustEpdGwp${type}${stage}`]
                        ? "#e1f7ea"
                        : "",
                    }}
                  />
                </Form.Group>
              </Col>
            ))}
            <Col>
              <Form.Group className="custom-form-group">
                <Form.Label className="custom-label">
                  <FontAwesomeIcon icon={getIcon(type)} /> C1-C4 GWP {type}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={formatValue(tabsData?.[activeTab]?.[`SustEpdGwp${type}C1C4`])}
                  onChange={(e) => {
                    const value = e.target.value.replace(',', '.');
                    if (value === '' || /^-?\d*\.?\d*$/.test(value)) {
                      handleChange({ target: { value } }, `SustEpdGwp${type}C1C4`);
                    }
                  }}
                  style={{
                    backgroundColor: changedFields.tabs?.[activeTab]?.[`SustEpdGwp${type}C1C4`]
                      ? "#e1f7ea"
                      : "",
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-4"></Row>
        </React.Fragment>
      ))}
    </>
  );
};

const getIcon = (type) => {
  switch (type) {
    case 'Total':
      return faCalculator;
    case 'Fossil':
      return faGasPump;
    case 'Biogenic':
      return faBiohazard;
    case 'Luluc':
      return faEarthEurope;
    default:
      return faCalculator;
  }
};

export default EndStage;