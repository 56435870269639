import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../features/epdProducts/epdProductSlice';
import ProductList from '../components/ProductList';

function ExtractEpd() {
  const dispatch = useDispatch();
  const { products, loading, error } = useSelector((state) => state.epdProducts);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  // Hantera laddnings- och felstatus här om det behövs

  return (
    <div>
      <ProductList products={products} />
    </div>
  );
}

export default ExtractEpd;
