import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk för att hämta EPD-statusräkningar
export const fetchEpdStatusCounts = createAsyncThunk(
  'epdStatusCounts/fetchEpdStatusCounts',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/epd/products-count-by-status`);
    return response.data;
  }
);

// Skapa en slice för EPD-statusräkningar
const epdStatusCountsSlice = createSlice({
  name: 'epdStatusCounts',
  initialState: {
    counts: {},
    loading: false,
    error: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEpdStatusCounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEpdStatusCounts.fulfilled, (state, action) => {
        state.loading = false;
        state.counts = action.payload;
      })
      .addCase(fetchEpdStatusCounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default epdStatusCountsSlice.reducer;
