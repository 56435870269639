import React, { useState, useEffect } from "react";
import UsageStage from "./ClimateValues/UsageStage";
import EndStage from "./ClimateValues/EndStage";
import BuildStage from "./ClimateValues/BuildStage";
import BenefitsAndLoads from "./ClimateValues/BenefitsAndLoads";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGasPump } from "@fortawesome/free-solid-svg-icons";
import { faBiohazard } from "@fortawesome/free-solid-svg-icons";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { faEarthEurope } from "@fortawesome/free-solid-svg-icons";
import {
  faInfoCircle,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  checkUrl,
  resetUrlIsValid,
} from "../../features/epdProducts/epdProductSlice";
import { debounce } from "lodash";

const ProductForm = ({
  formData,
  tabsData,
  activeTab,
  handleChange,
  handleCommonFieldChange,
  changedFields,
  copyCalculatedDataToA1A3,
}) => {
  const activeProduct = useSelector((state) => state.epd.activeProduct);
  const dispatch = useDispatch();
  const urlIsValid = useSelector((state) => state.epdProducts.urlIsValid);

  const debouncedCheckUrl = debounce((url) => {
    dispatch(checkUrl(url));
  }, 50); // 500 ms debounce-tid

  const buildAndCheckUrl = () => {
    const url = `${formData.ExtractaExternalDataLinkProvider}${formData.SustExternalId}?format=xml`;
    debouncedCheckUrl(url);
  };

  useEffect(() => {
    dispatch(resetUrlIsValid()); // Återställ urlIsValid när komponenten laddas

    if (formData.ExtractaExternalDataLinkProvider && formData.SustExternalId) {
      buildAndCheckUrl();
    }

    return () => {
      debouncedCheckUrl.cancel(); // Rensa debounce-timern
    };
  }, [formData.ExtractaExternalDataLinkProvider, formData.SustExternalId]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Beräkningen utförs baserat på värdena från GWP Fossil, GWP Luluc och GWP
      Biogenic om de är ifyllda. Standarden EN 15804:2012+A2:2019 måste
      användas.
    </Tooltip>
  );

  console.log("SustAssetLinkEpd:", formData.SustAssetLinkEpd);

  return (
    <Form>
      <Row>
        <Col md={4}>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">Checksumma</Form.Label>
            <Form.Control
              type="text"
              value={formData.SustEpdChecksum || ""}
              readOnly
              style={{ backgroundColor: "#f3f3f3" }}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">Ägare av EPD</Form.Label>
            <Form.Control
              type="text"
              value={formData.SustEpdOwner || ""}
              onChange={(e) => {
                handleCommonFieldChange(e, "SustEpdOwner");
              }}
              style={{
                backgroundColor: changedFields.common?.SustEpdOwner
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col md={4}></Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">Publisher</Form.Label>
            <Form.Control
              type="text"
              value={formData.SustEpdPublisher || ""}
              onChange={(e) => handleCommonFieldChange(e, "SustEpdPublisher")}
              style={{
                backgroundColor: changedFields.common?.SustEpdPublisher
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">Giltighetsdatum</Form.Label>
            <Form.Control
              type="date"
              value={formData.SustEpdValidTo.split("T")[0] || ""}
              onChange={(e) => handleCommonFieldChange(e, "SustEpdValidTo")}
              style={{
                backgroundColor: changedFields.common?.SustEpdValidTo
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              EPD-/deklarationsnummer
            </Form.Label>
            <Form.Control
              type="text"
              value={formData.SustEpdId || ""}
              onChange={(e) => handleCommonFieldChange(e, "SustEpdId")}
              style={{
                backgroundColor: changedFields.common?.SustEpdId
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">EPD-standard</Form.Label>
            <Form.Select
              value={formData.SustEpdStandard || ""}
              onChange={(e) => {
                handleCommonFieldChange(e, "SustEpdStandard");
                // calculateSustEpdA1A3Calc();
              }}
              style={{
                backgroundColor: changedFields.common?.SustEpdStandard
                  ? "#e1f7ea"
                  : "",
              }}
            >
              <option value="">Välj en standard</option>
              <option value="EN 15804:2012+A1:2013">
                EN 15804:2012+A1:2013
              </option>
              <option value="EN 15804:2012+A2:2019">
                EN 15804:2012+A2:2019
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col></Col>
      </Row>
      <Col md={8}>
        <Form.Group className="custom-form-group">
          <Form.Label className="custom-label">Produktnamn</Form.Label>
          <Form.Control
            type="text"
            value={tabsData?.[activeTab]?.SustEpdProductName || ""}
            onChange={(e) => handleChange(e, "SustEpdProductName")}
            style={{
              backgroundColor: changedFields.tabs?.[activeTab]
                ?.SustEpdProductName
                ? "#e1f7ea"
                : "",
            }}
          />
        </Form.Group>
      </Col>
      <Col md={8}>
        <Form.Group className="custom-form-group">
          <Form.Label className="custom-label">Modell</Form.Label>
          <Form.Control
            type="text"
            value={tabsData?.[activeTab]?.SustEpdModel || ""}
            onChange={(e) => handleChange(e, "SustEpdModel")}
            style={{
              backgroundColor: changedFields.tabs?.[activeTab]?.SustEpdModel
                ? "#e1f7ea"
                : "",
            }}
          />
        </Form.Group>
      </Col>
      <Row>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">Funktionell enhet</Form.Label>
            <Form.Control
              type="text"
              value={formData.SustEpdFunctionalUnit || ""}
              onChange={(e) =>
                handleCommonFieldChange(e, "SustEpdFunctionalUnit")
              }
              style={{
                backgroundColor: changedFields.common?.SustEpdFunctionalUnit
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              Vikt på funktionell enhet (kg){" "}
            </Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdFunctionalUnitWeight || ""}
              onChange={(e) => handleChange(e, "SustEpdFunctionalUnitWeight")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]
                  ?.SustEpdFunctionalUnitWeight
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              Finns information om biogent kol?
            </Form.Label>
            <div>
              <Form.Check
                inline
                type="radio"
                label="Ja"
                name="SustBiogenEpd"
                id="SustBiogenEpd-yes"
                checked={formData.SustBiogenEpd === true}
                onChange={() =>
                  handleCommonFieldChange(
                    { target: { value: true } },
                    "SustBiogenEpd"
                  )
                }
                // style={{
                //   backgroundColor: changedFields.common?.SustBiogenEpd
                //     ? "#e1f7ea"
                //     : "",
                // }}
              />
              <Form.Check
                inline
                type="radio"
                label="Nej"
                name="SustBiogenEpd"
                id="SustBiogenEpd-no"
                checked={formData.SustBiogenEpd === false}
                onChange={() =>
                  handleCommonFieldChange(
                    { target: { value: false } },
                    "SustBiogenEpd"
                  )
                }
                // style={{
                //   backgroundColor: changedFields.common?.SustBiogenEpd
                //     ? "#e1f7ea"
                //     : "",
                // }}
              />
            </div>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">Kommentar</Form.Label>
            <Form.Select
              value={formData.SustEpdComment || ""}
              onChange={(e) => handleCommonFieldChange(e, "SustEpdComment")}
              style={{
                backgroundColor: changedFields.common?.SustEpdComment
                  ? "#e1f7ea"
                  : "",
              }}
            >
              <option value="">Välj en kommentar</option>
              <option value="Ej utgiven av programoperatör, därmed ej giltig EPD.">
                Ej utgiven av programoperatör, därmed ej giltig EPD.
              </option>
              <option value="Generisk EPD för flera produkter, se tabell i EPD.">
                Generisk EPD för flera produkter, se tabell i EPD.
              </option>
              <option value="Bör ej användas för klimatdeklaration, biogent kol kan vara inräknat  i värdet.">
                Bör ej användas för klimatdeklaration, biogent kol kan vara
                inräknat i värdet.
              </option>
              <option value="Skall ej användas för klimatdeklaration, biogent kol är inräknat i värden.">
                Kan ej användas för klimatdeklaration, biogent kol är inräknat i
                värden.
              </option>
              <option value="Saknar redovisad vikt på funktionell enhet.">
                Saknar redovisad vikt på funktionell enhet.
              </option>
              <option value="Saknar relevanta värden för klimatdeklaration.">
                Saknar relevanta värden för klimatdeklaration.
              </option>
              <option value="Worst case product redovisad, stor skillnad mellan produkterna.">
                Worst case product redovisad, stor skillnad mellan produkterna.
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
        {formData.SustAssetLinkEpd && (
          <Col
            className="d-flex justify-content-center"
            style={{ marginTop: "20px" }}
          >
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  formData.SustAssetLinkEpd,
                  "newWindow",
                  "width=1200,height=1200"
                );
              }}
              style={{ width: "80%" }}
            >
              <Button
                variant="outline-success"
                size="lg"
                style={{ width: "100%" }}
              >
                Öppna länkad EPD
              </Button>
            </a>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">
              A1-A3 GWP-GHG härstammar från beräkning?
            </Form.Label>
            <div>
              <Form.Check
                inline
                label="Ja"
                name="ExtractaCalculateGwp"
                type="radio"
                id={`ExtractaCalculateGwp-yes`}
                value={true} // Notera att vi inte längre använder strängvärden här
                checked={formData.ExtractaCalculateGwp === true} // Jämför med true direkt
                onChange={() =>
                  handleCommonFieldChange(
                    { target: { value: true } },
                    "ExtractaCalculateGwp"
                  )
                }
              />

              <Form.Check
                inline
                label="Nej"
                name="ExtractaCalculateGwp"
                type="radio"
                id={`ExtractaCalculateGwp-no`}
                value={false}
                checked={formData.ExtractaCalculateGwp === false}
                onChange={() =>
                  handleCommonFieldChange(
                    { target: { value: false } },
                    "ExtractaCalculateGwp"
                  )
                }
              />
            </div>
          </Form.Group>
        </Col>
        <Col></Col>
      </Row>

      {["validation", "export", "exported"].includes(
        activeProduct.ExtractaStatus
      ) && (
        <>
          <Row className="mt-4 mb-2">
            <Col>
              <hr />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <h5>
                <span
                  style={{
                    cursor: "pointer",
                    color: "black",
                    textDecoration: "none",
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.eco-platform.org/epd-data.html",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  Externa länkar
                </span>
              </h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="custom-form-group">
                <Form.Label className="custom-label">
                  Externt dataset källa
                </Form.Label>
                <Form.Select
                  value={formData.ExtractaExternalDataLinkProvider || ""}
                  onChange={(e) =>
                    handleCommonFieldChange(
                      e,
                      "ExtractaExternalDataLinkProvider"
                    )
                  }
                  style={{
                    backgroundColor: changedFields.common
                      ?.ExtractaExternalDataLinkProvider
                      ? "#e1f7ea"
                      : "",
                  }}
                >
                  <option value="">Välj källa</option>
                  <option value="https://data.eco-platform.org/resource/processes/">
                    ECO Portal
                  </option>
                  <option value="https://ecosmdp.eco-platform.org/resource/processes/">
                    ECO Platform Small Data Providers Node
                  </option>
                  <option value="https://ibudata.lca-data.com/resource/processes/">
                    IBU.data
                  </option>
                  <option value="https://epdnorway.lca-data.com/resource/processes/">
                    EPD Norway Digi
                  </option>
                  <option value="https://data.environdec.com/resource/processes/">
                    EPD International Data Hub
                  </option>
                  <option value="https://node.epditaly.it/Node/resource/processes/">
                    Node ICMQ SPA
                  </option>
                  <option value="https://data.mrpi.nl/resource/processes/">
                    MRPI
                  </option>
                  <option value="https://epdireland.lca-data.com/resource/processes/">
                    EPD Ireland
                  </option>
                  <option value="https://itb.lca-data.com/resource/processes/">
                    ITB Poland
                  </option>
                  <option value="https://soda4lca.bregroup.com/resource/processes/">
                    BRE Global EPD online tool
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="custom-form-group">
                <Form.Label className="custom-label">
                  Externt dataset ID
                </Form.Label>
                <Form.Control
                  type="text"
                  value={formData.SustExternalId || ""}
                  onChange={(e) => handleCommonFieldChange(e, "SustExternalId")}
                  style={{
                    backgroundColor: changedFields.common?.SustExternalId
                      ? "#e1f7ea"
                      : "",
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={11}>
              <Form.Group className="custom-form-group">
                <Form.Label className="custom-label">
                  EPD dataset URL
                </Form.Label>
                <Form.Control
                  type="text"
                  value={formData.SustExternalDataLink || ""}
                  readOnly
                  style={{
                    backgroundColor: changedFields.common?.SustExternalDataLink
                      ? "#e1f7ea"
                      : "",
                  }}
                />
              </Form.Group>
            </Col>
            <Col
              md={1}
              className="d-flex align-items-center justify-content-center"
              style={{ marginTop: "10px" }} // Flytta ikonen nedåt
            >
              {urlIsValid === true ? (
                <FontAwesomeIcon icon={faCheckCircle} color="green" size="2x" /> // Gör ikonen större
              ) : urlIsValid === false ? (
                <FontAwesomeIcon icon={faTimesCircle} color="red" size="2x" /> // Gör ikonen större
              ) : null}
            </Col>
          </Row>
        </>
      )}

      {/* Början av formulär */}

      <Row className="mt-4 mb-2">
        <Col>
          <hr />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <h5>Klimatvärden - Byggskede</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">A1 GWP-GHG</Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdA1 || ""}
              onChange={(e) => handleChange(e, "SustEpdA1")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]?.SustEpdA1
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">A2 GWP-GHG</Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdA2 || ""}
              onChange={(e) => handleChange(e, "SustEpdA2")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]?.SustEpdA2
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">A3 GWP-GHG</Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdA3 || ""}
              onChange={(e) => handleChange(e, "SustEpdA3")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]?.SustEpdA3
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">A1-A3 GWP-GHG</Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdA1A3 || ""}
              onChange={(e) => handleChange(e, "SustEpdA1A3")}
              style={{
                backgroundColor: changedFields?.tabs?.[activeTab]?.SustEpdA1A3
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">A4 GWP-GHG</Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdA4 || ""}
              onChange={(e) => handleChange(e, "SustEpdA4")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]?.SustEpdA4
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="custom-form-group">
            <Form.Label className="custom-label">A5 GWP-GHG</Form.Label>
            <Form.Control
              type="number"
              step="any" // Tillåter decimaler
              pattern="\d+(\.\d{0,5})?" // Regular expression för att matcha numeriska värden med upp till två decimaler
              value={tabsData?.[activeTab]?.SustEpdA5 || ""}
              onChange={(e) => handleChange(e, "SustEpdA5")}
              style={{
                backgroundColor: changedFields.tabs?.[activeTab]?.SustEpdA5
                  ? "#e1f7ea"
                  : "",
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      {activeProduct.ExtractaStatus === "validation" && (
        <Row>
          <Col>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-label">
                A1-A3 GWP-GHG BERÄKNAD
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 125, hide: 200 }}
                  overlay={renderTooltip}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ marginLeft: "5px" }}
                  />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="number"
                value={tabsData?.[activeTab]?.SustEpdA1A3Calc || ""}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            {activeProduct.ExtractaStatus === "validation" &&
              tabsData?.[activeTab]?.SustEpdA1A3Calc > 0 && (
                <Button
                  variant="danger"
                  size="l"
                  style={{ width: "80%", marginTop: "25px" }}
                  onClick={copyCalculatedDataToA1A3} // Använd funktionen som skickades via props
                >
                  Kopiera beräknad data
                </Button>
              )}
          </Col>
          <Col></Col>
        </Row>
      )}

      <br />
      <br />

      {/* Slut på formulär */}

      <BuildStage
        tabsData={tabsData}
        activeTab={activeTab}
        handleChange={handleChange}
        changedFields={changedFields}
      />

      <Row className="mt-4 mb-2">
        <Col>
          <hr />
        </Col>
      </Row>

      <UsageStage
        tabsData={tabsData}
        activeTab={activeTab}
        handleChange={handleChange}
        changedFields={changedFields}
      />

      <Row className="mt-4 mb-2">
        <Col>
          <hr />
        </Col>
      </Row>

      <EndStage
        tabsData={tabsData}
        activeTab={activeTab}
        handleChange={handleChange}
        changedFields={changedFields}
      />

      <Row className="mt-4 mb-2">
        <Col>
          <hr />
        </Col>
      </Row>

      <BenefitsAndLoads
        tabsData={tabsData}
        activeTab={activeTab}
        handleChange={handleChange}
        changedFields={changedFields}
      />
    </Form>
  );
};

export default ProductForm;
