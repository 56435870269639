import React from 'react';
import { Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton = () => {
  const { logout } = useAuth0();
  const logoutUrl = process.env.REACT_APP_LOGOUT_URL; 

  const onLogout = () => {
    logout({ 
      returnTo: logoutUrl 
    });
  };

  return (
    <Button variant="secondary" onClick={onLogout}>
      Logga ut
    </Button>
  );
};

export default LogoutButton;
